<template>
  <div class="wrapper">
    <div>
      <!-- personname: this.formInline.personname,	//姓名	query	String		
        personcard: this.formInline.personcard,	//身份证号	query	String		
        censusaddr: this.formInline.censusaddr,	//户籍地	query	String		
        housename: this.formInline.housename,	//居住地址	query	String		
        regionname: this.formInline.regionname,	//小区名称	query	String		
        starttime: this.formInline.time && this.formInline.time.length ?this.formInline.time[0]:'',	//录入系统起始时间(YYYYMMDD) 默认本月1号	query	String		
        endtime: this.formInline.time && this.formInline.time.length ?this.formInline.time[1]:'',	//录入系统结束时间(YYYYMMDD) 默认本月最后1天	query	String		
        doing: this.formInline.doing,	//操作类型传 字符串add或者del 不能为空	query	string		 -->
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="formInline.personname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="formInline.personcard" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="户籍地">
          <el-input v-model="formInline.censusaddr" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="居住地址">
          <el-input v-model="formInline.housename" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="小区名称">
          <el-input v-model="formInline.regionname" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="formInline.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyyMMdd"
            format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="操作类型">
          <el-select v-model="formInline.doing" placeholder="请选择">
            <el-option label="新增人员" value="add"></el-option>
            <el-option label="删除人员" value="del"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button type="primary" @click="exportExl">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      
      <el-table
        v-loading="loading"
        :data="tableData"
        stripe
        border
        style="width: 100%">
        <el-table-column
          prop="personname"
          label="姓名"
          width="100">
        </el-table-column>
        <el-table-column
          prop="person_sex"
          label="性别"
          width="60">
        </el-table-column>
        <el-table-column
          prop="personphone"
          label="电话"
          width="120">
        </el-table-column>
        <el-table-column
          prop="personcard"
          label="身份证"
          width="160">
        </el-table-column>
        <el-table-column
          prop="regionname"
          label="小区">
        </el-table-column>
        <el-table-column
          prop="housename"
          label="房屋地址"
          width="200">
        </el-table-column>
        <el-table-column
          prop="censusaddr"
          label="户籍地址"
          width="200">
        </el-table-column>
        <el-table-column
          prop="doingtype"
          label="操作类型">
        </el-table-column>
        <el-table-column
          prop="dotime"
          label="操作时间"
          width="140">
        </el-table-column>
        <el-table-column
          prop="descinfo"
          label="操作描述"
          width="200">
        </el-table-column>
        <el-table-column
          prop="name"
          label="网格员">
        </el-table-column>
        <el-table-column
          prop="grid_name"
          label="网格名称"
          width="200">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { doingPersonList, expordoingPersonList } from '@/api/shaicha.js'
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      tableData: [],
      formInline: {
        personname: '',
        personcard: '',
        censusaddr: '',		
        housename: '',
        regionname: '',
        time: [],
        starttime: '',
        endtime: '',
        doing: 'add'
      },
      fenye: {
        pagesizes: [10, 20, 30, 40],
        pagesize: 10,
        totalnum: 0
      },
      currentPage: 1,
      loading: false
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.formInline.time = [this.getCurrentMonthFirst(), this.getLastDayOfMonth()]
    this.onSubmit();
  },
  computed:{},
  methods:{
    // 获取当月的第一天
    getCurrentMonthFirst() {
      let date = new Date();
      date.setDate(1);
      let month = parseInt(date.getMonth() + 1);
      let day = date.getDate();
      if (month < 10) {
          month = '0' + month
      }
      if (day < 10) {
          day = '0' + day
      }
      return date.getFullYear() + '' + month + '' + day;
    },
    // 获取当月的最后一天
    getLastDayOfMonth() {
      let date = new Date();
      let currentMonth = date.getMonth();
      let nextMonth = ++currentMonth;
      let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24; //js单位是毫秒，60*1000转化为秒
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let month = parseInt(lastTime.getMonth() + 1);
      let day = lastTime.getDate();
      if (month < 10) {
          month = '0' + month
      }
      if (day < 10) {
          day = '0' + day
      }
      return date.getFullYear() + '' + month + '' + day;
    },
    onSubmit(){
      this.currentPage = 1;
      this.getList();
    },
    //导出
    exportExl(){
      if(!this.formInline.time || !this.formInline.time.length){
        this.$message.warning('请选择时间范围');
        return false;
      }
      expordoingPersonList({
        'x-access-token': this.GLOBAL.token(),
				"userid": this.GLOBAL.adminId(),
        personname: this.formInline.personname,	//姓名	query	String		
        personcard: this.formInline.personcard,	//身份证号	query	String		
        censusaddr: this.formInline.censusaddr,	//户籍地	query	String		
        housename: this.formInline.housename,	//居住地址	query	String		
        regionname: this.formInline.regionname,	//小区名称	query	String		
        starttime: this.formInline.time && this.formInline.time.length ?this.formInline.time[0]:'',	//录入系统起始时间(YYYYMMDD) 默认本月1号	query	String		
        endtime: this.formInline.time && this.formInline.time.length ?this.formInline.time[1]:'',	//录入系统结束时间(YYYYMMDD) 默认本月最后1天	query	String		
        doing: this.formInline.doing,	//操作类型传 字符串add或者del 不能为空	query	string		
        page: this.currentPage,	//页码	query	int		
        pagesize: 999999999,	//每页显示多少条
      }).then((res) => {
      if (res.result == '200') {
        FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
      } else {
        this.$message.error(res.description);
      }
    })
    },
    getList() {
      if(!this.formInline.time || !this.formInline.time.length){
        this.$message.warning('请选择时间范围');
        return false;
      }
      
      this.loading = true;
      doingPersonList({
        'x-access-token': this.GLOBAL.token(),
				"userid": this.GLOBAL.adminId(),
        personname: this.formInline.personname,	//姓名	query	String		
        personcard: this.formInline.personcard,	//身份证号	query	String		
        censusaddr: this.formInline.censusaddr,	//户籍地	query	String		
        housename: this.formInline.housename,	//居住地址	query	String		
        regionname: this.formInline.regionname,	//小区名称	query	String		
        starttime: this.formInline.time && this.formInline.time.length ?this.formInline.time[0]:'',	//录入系统起始时间(YYYYMMDD) 默认本月1号	query	String		
        endtime: this.formInline.time && this.formInline.time.length ?this.formInline.time[1]:'',	//录入系统结束时间(YYYYMMDD) 默认本月最后1天	query	String		
        doing: this.formInline.doing,	//操作类型传 字符串add或者del 不能为空	query	string		
        page: this.currentPage,	//页码	query	int		
        pagesize: this.fenye.pagesize,	//每页显示多少条
      }).then(res=>{
        this.loading = false;
        if(res.result == 200){
          this.tableData = res.detail.list;
          this.fenye.totalnum = res.detail.totalRow;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
  .pagebox{
    padding: 20px 0;
  }
</style>